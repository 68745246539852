import _config from '@/config';
import store from '@/store';
import service from '@/api-service';
import { Toast } from 'vant';

var wx = require('weixin-js-sdk') || window['wx'];

export function wxLogin(redirectUrl) {
    return new Promise((resolve, reject) => {
        let code = decodeURIComponent((new RegExp('[?|&]' + 'code' + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
        if (code) {
            service.getWxOpenId({ code }).then((res) => {
                if (res.code === 0) {
                    let token = res.token;
                    // console.log('openId', openId);
                    // store.commit('setOpenId', openId);
                    console.log('auth ok');
                    store.commit("setAuthCode", token);
                    resolve(true);
                    return
                } else {
                    console.log('reject');
                    Toast.fail(res.msg);
                    reject(false);
                }
                console.log('resolve');
                resolve();
            }).catch((err) => {
                console.log(err)
                Toast.fail('微信授权失败');
                reject(false);
            });
        } else {
            // redirectUrl = "https://m.piaoxiaodan.com"
            let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${_config.wxAppId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect`;
            // let url = redirectUrl + '&code=123'
            window.location.replace(url);
            reject(false);
        }

    })

}

export function wxPaymentV2(config, callback) {
    if (typeof WeixinJSBridge === 'undefined') { // 微信浏览器内置对象。参考微信官方文档
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(config), false)
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(config))
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(config))
        }
    } else {
        console.log('准备调用微信支付')
        onBridgeReady(config, callback);
    }
}

function onBridgeReady(config, callback) {
    WeixinJSBridge.invoke('getBrandWCPayRequest', {
        appId: config.appId,
        timeStamp: config.timeStamp,
        nonceStr: config.nonceStr,
        package: config.package,
        signType: config.signType,
        paySign: config.paySign,
    }, (res) => {
        console.log('调用成功');
        console.log(res);
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
            if (callback) {
                callback();
            }
        }
    })
}


export function wxPaymentV3(wxConfig) {
    return new Promise((resolve, reject) => {
        readyConfig(wxConfig).then((res) => {
            wx.chooseWXPay({
                appId: wxConfig.appId,
                timestamp: wxConfig.timeStamp,
                nonceStr: wxConfig.nonceStr,
                package: wxConfig.package,
                paySign: wxConfig.paySign,
                success: function(res) {
                    console.log('支付好了');
                    resolve();
                },
                cancel: function(res) {
                    console.log('取消支付');
                },
                fail: function(res) {
                    console.log('支付失败');
                }
            })
        });
    })
}


export function wxScanCode(wxConfig) {
    return new Promise((resolve, reject) => {
        readyConfig(wxConfig, ['scanQRCode']).then(() => {
            wx.scanQRCode({
                needResult: 1,
                success: function(res) {
                    resolve(res.resultStr);
                },
                fail: err => reject(err)
            });
        })
    })
}

export function readyConfig({ appid, timestamp, nonceStr, signature }, jsApiList) {
    return new Promise((resolve, reject) => {
        console.log('weixin是什么 -> ', wx);
        let config = {
            debug: false,
            appId: appid,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature,
            jsApiList: ['checkJsApi', 'scanQRCode']
        }

        wx.config(config);

        wx.ready(() => {
            console.log('微信Config配置成功 v1')
            resolve();
        });

        wx.error((error) => {
            console.log('微信Config配置错误 v1', error);
            reject();
        })
    })
}
